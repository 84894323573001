<template>
  <div>
    <el-form-item
      :label="__('Alternative Languages')"
      v-show="showAlternateLanguageOptions"
    >
      <el-select
        v-model="user_input_language"
        ref="user_input_language"
        style="width: 100%;"
        :placeholder="__('Please select up to 3 languages')"
        default-first-option
        filterable
        :multiple-limit="3"
        multiple
        clearable
        :disabled="alternateLanguageSelectDisabled"
        :no-data-text="noText"
        :loading-text="__('fetching languages')"
        class="alternate-language-select"
        :class="{
          'alternate-language-disabled': alternateLanguageSelectDisabled
        }"
      >
        <el-option
          v-for="(item, index) in filteredLanguages"
          :label="item.language_name"
          :value="item.language_id"
          :key="index"
        />
      </el-select>
      <div class="tooltips">
        {{ asr_restriction }}
      </div>
    </el-form-item>
    <el-form-item :label="__('Override Language Variable')">
      <language-variable
        ref="language_variable_id"
        :value="languageVariable"
        @lang-var-changed="handleLanguageVarChange"
      />
    </el-form-item>
    <el-form-item
      :label="__('Assign detected language to variable')"
      v-show="showAlternateLanguageOptions"
    >
      <create-or-select
        :items="singleValuedAndSecureVariables"
        label="variable_name"
        value="variable_id"
        :placeholder="__('Select or create a new variable')"
        :new-item-message="__('new variable')"
        :current_select="currentSelect"
        @change="handleLanguageVariableSelectionChange"
        style="width: 100%;"
      />
      <el-col :span="4">
        <span
          ref="row-message"
          class="row-message"
          v-if="newVariableForDetectedLanguageVar"
          >{{ __("new variable") }}</span
        >
      </el-col>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageVariable from "@/views/build/callflow/components/node-type-forms/components/LanguageVariable";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";

export default {
  name: "LanguageSettings",
  components: {
    LanguageVariable,
    CreateOrSelect
  },
  props: {
    alternateLanguageSelectDisabled: {
      required: false,
      type: Boolean,
      default: false
    },
    selectedLanguageIds: {
      required: false,
      type: Array,
      default: () => []
    },
    languageVariable: {
      required: false,
      type: Number,
      default: null
    },
    filteredLanguages: {
      required: false,
      type: Array,
      default: () => []
    },
    currentSelect: {
      required: true
    },
    showAlternateLanguageOptions: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      preText: "",
      newVariableForDetectedLanguageVar: 0
    };
  },
  computed: {
    ...mapGetters("variables", {
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables"
    }),

    asr_restriction() {
      return __(
        "Alternative languages are only supported by ASR: Default and ASR: Command and Search Recogniser Models."
      );
    },
    noText() {
      return (
        this.preText +
        " ASR languages are not provisioned in your business plan"
      );
    },
    user_input_language: {
      get: function() {
        if (!this.selectedLanguageIds) {
          return [];
        }
        return this.selectedLanguageIds;
      },
      set: function(newValue) {
        if (!newValue) {
          newValue = [];
        }
        this.$emit("input-languages-change", newValue);
      }
    }
  },
  methods: {
    handleLanguageVarChange(value) {
      this.$emit("language-var-change", value);
    },
    handleLanguageVariableSelectionChange(option) {
      this.newVariableForDetectedLanguageVar = 0;
      if (option.value === -1) {
        this.newVariableForDetectedLanguageVar = 1;
      }
      this.$emit("detect-lang-var-change", option);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";

.el-select.alternate-language-select.el-select--medium.alternate-language-disabled {
  opacity: 0.5;
}
</style>
